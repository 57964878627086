import React from 'react';
import IntroductionImage from '../images/Main-picture.png';
import './Introduction.css';
import POSimage from '../images/POSpicture.png';
import introBottom from '../images/introductionBottom.svg';
import introTop from '../images/introductionTop.png';
import LanguageChange from '../languagechange/LanguageChange';
import { useTranslation } from "react-i18next";
import POSimageBackground from '../images/introPictureBackground.png'



const Introduction = () => {
  let classNames = [];
  if (localStorage.getItem('storedLanguage') === "ge") {
    classNames.push('georgianStyleIntro')
  } else if (localStorage.getItem('storedLanguage') === "en") {
    classNames.push('englishStyleIntro')
  } else if (localStorage.getItem('storedLanguage') === "ru") {
    classNames.push('russianStyleIntro')
  } else {
    classNames.push("englishStyleIntro")
  }
    const [t, i18n] = useTranslation("global");

  return (
    <div className='container block'>
      
        <img className='posimage' src={POSimage} alt="" />
        <img className='posimageBackground' src={POSimageBackground} alt="" />
        <img className='introBottom' src={introBottom} alt="" />
        <p className={`top-left ${classNames.join(" ")} `}> 
        <span className={`posInfrastructure ${classNames.join(" ")}`}>
          <span className='englishStyleIntro POS'>{t("imageText.POS")} </span> 
          <span>{t("imageText.firstLine")}</span>
          </span>
        <span>{t("imageText.secondLine")}</span> 
        <span>{t("imageText.thirdLine")}</span> <span>{t("imageText.fourthLine")}</span></p>

    </div>
  )
}

export default Introduction