import React from 'react';
import './block2.css'
import underConstruction from '../images/underConstruction.png'

const Block2 = () => {
  return (
    <div className='block2'>
    <img src={underConstruction} className='UNDER-CONSTRACTION' />
    
    </div>
  )
}

export default Block2