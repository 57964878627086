import React from 'react';
import './gridBlock.css'
import underConstruction from '../images/underConstruction.png'


const GridBlock = () => {
  return (
    <div class="gridBlock">
        <div class="gridBlock1">
    <img src={underConstruction} className='UNDER-CONSTRACTION' />
        
        </div>
        <div class="gridBlock2"> 
    <img src={underConstruction} className='UNDER-CONSTRACTION' />
        
        </div>
    </div>
  )
}

export default GridBlock