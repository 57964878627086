import React from 'react'
import { useTranslation } from "react-i18next";
import "./nav.css"
import LanguageChange from '../languagechange/LanguageChange';
import mainlogo from '../images/Logo.svg'
import Burger from './Burger';
import styled from 'styled-components';


const PhoneNavbar = ({open}) => {



  let classNames = [];
  if (localStorage.getItem('storedLanguage') === "ge") {
    classNames.push('georgianStyle')
  } else if (localStorage.getItem('storedLanguage') === "en") {
    classNames.push('englishStyle')
  } else if (localStorage.getItem('storedLanguage') === "ru") {
    classNames.push('russianStyle')
  } else {
    classNames.push("englishStyle")
  }

  let classNamesDemoButton = [];
  if (localStorage.getItem('storedLanguage') === "ge") {
    classNamesDemoButton.push('georgianStyle georgianStyleDemoButton')
  } else if (localStorage.getItem('storedLanguage') === "en") {
    classNamesDemoButton.push('englishStyle englishStyleDemoButton')
  } else if (localStorage.getItem('storedLanguage') === "ru") {
    classNamesDemoButton.push('russianStyle russianStyleDemoButton')
  } else {
    classNamesDemoButton.push("englishStyle")
  }
    const [t, i18n] = useTranslation("global");

    window.addEventListener('scroll', () => {
      console.log("test")
    })

        const PhoneNav = styled.ul`
            text-decoration: none;
            text-align: center;
            padding: 0;
            list-style-type: none;
            gap: 1rem;
            justify-content: space-around;
            font-size: 19px;
            /* grid-template-columns: repeat(3, 2fr); */
            justify-items: center;
            align-items: center;
            display: ${({  open }) => open ? 'grid' : 'none'};

        li {
            margin:0;
            padding:0;
        }
        @media only screen and (min-width: 900px) {
    display: none;
}
    
        `
    
  return (

        <PhoneNav open={open} className='navBarPhone'>
                <li className={` ${classNames.join(" ")}`}>{t("navbar.restaurant")}</li>
                <li className={` ${classNames.join(" ")}`}>{t("navbar.functional")}</li>
                <li className={` ${classNames.join(" ")}`}>{t("navbar.prices")}</li>
                <li className={` ${classNames.join(" ")}`}>{t("navbar.services")}</li>
                <li className={` ${classNames.join(" ")}`}>{t("navbar.aboutus")}</li>
        </PhoneNav>
  )
}

export default PhoneNavbar;