import React from 'react'
import { useTranslation } from "react-i18next";
import "./nav.css"
import LanguageChange from '../languagechange/LanguageChange';
import mainlogo from '../images/Logo.svg'
import Burger from './Burger';


const Nav = () => {
  let classNames = [];
  if (localStorage.getItem('storedLanguage') === "ge") {
    classNames.push('georgianStyle')
  } else if (localStorage.getItem('storedLanguage') === "en") {
    classNames.push('englishStyle')
  } else if (localStorage.getItem('storedLanguage') === "ru") {
    classNames.push('russianStyle')
  } else {
    classNames.push("englishStyle")
  }

  let classNamesDemoButton = [];
  if (localStorage.getItem('storedLanguage') === "ge") {
    classNamesDemoButton.push('georgianStyle georgianStyleDemoButton')
  } else if (localStorage.getItem('storedLanguage') === "en") {
    classNamesDemoButton.push('englishStyle englishStyleDemoButton')
  } else if (localStorage.getItem('storedLanguage') === "ru") {
    classNamesDemoButton.push('russianStyle russianStyleDemoButton')
  } else {
    classNamesDemoButton.push("englishStyle")
  }
    const [t, i18n] = useTranslation("global");

    window.addEventListener('scroll', () => {
      console.log("test")
    })
    
    
  return (
    <div className={`navBarFull`}>
      <div className='fullNavBar'>
        <div className="fullLogo ">
          <img src={mainlogo} alt="logo" />
        </div>
        <div className="gridBar">
          <div className="navBarUpperside">
          <LanguageChange />
          <a href="tel:+995 574 55 9955">+995 (574) 55 99 55</a>
          </div>
          <div className="navBarFlex">
          <ul className='navBar' >
          <li className={` ${classNames.join(" ")}`}>{t("navbar.restaurant")}</li>
          <li className={` ${classNames.join(" ")}`}>{t("navbar.functional")}</li>
          <li className={` ${classNames.join(" ")}`}>{t("navbar.prices")}</li>
          <li className={` ${classNames.join(" ")}`}>{t("navbar.services")}</li>
          <li className={` ${classNames.join(" ")}`}>{t("navbar.aboutus")}</li>
          </ul>
          <button className={`navBarButton ${classNamesDemoButton.join(" ")}` }>{t("navbar.upperside.button")}</button>
          </div>
            </div>  
            </div>

            <Burger className='burger' />
    </div>
  )
}

export default Nav