import React from 'react';
import logo from './logo.svg';
import './App.css';
import LanguageChange from './components/languagechange/LanguageChange.jsx';
import Nav from './components/nav/Nav';
import Introduction from './components/introduction/Introduction';
import Block from './components/block/Block';
import Block2 from './components/block2/Block2';
import GridBlock from './components/gridblock/GridBlock';
import Footer from './components/footer/Footer';


function App() {
  return (
    <div className="App">
      <Nav />
      <Introduction />
      <Block />
      <Block2 />
      <GridBlock />
      <Footer />
    </div>
    );
  }
  
export default App;
