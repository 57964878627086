import React from "react";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import "./LanguageChange.css"

const LanguageChange = () => {
  const [t, i18n] = useTranslation("global")
  
  const [lg, setlg] = useState(localStorage.getItem('storedLanguage') || []);
  useEffect(() => {
    localStorage.setItem('storedLanguage', lg);
  }, [lg]);

  const handleChangeLanguage = (lang: lg) => {
    i18n.changeLanguage(lang);
  }
  if(localStorage.getItem('storeLanguage') === true ) {
    console.log("yes")
    handleChangeLanguage(localStorage.getItem('storeLanguage'));
  }

  const selectChange = (event) => {
    const value = event.target.value
    console.log(value);
    setlg(value);
    handleChangeLanguage(lg)
    window.location.reload(false);
  }
  return (
    <div className="languageChangeButtons">
    
    <select value={localStorage.getItem('storedLanguage')} onChange={selectChange} >
      <option value='en' >English</option>
      <option value='ge'>Georgian</option>
      <option value='ru'>Russian</option>
    </select>
    </div>
  )
}

export default LanguageChange;