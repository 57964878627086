import React from 'react';
import './block.css'
import underConstruction from '../images/underConstruction.png'

const Block = () => {
  return (
    <div className='firstBlock'>
      <img src={underConstruction} className='UNDER-CONSTRACTION' />
    </div>
  )
}

export default Block