import React from 'react'
import './footer.css'
import map from '../images/georgianMap.png'
import {FaFacebook, FaInstagram, FaLinkedin} from 'react-icons/fa'
import { useTranslation } from "react-i18next";
import LanguageChange from '../languagechange/LanguageChange';



const Footer = () => {
  let classNamesFooter = [];
  if (localStorage.getItem('storedLanguage') === "ge") {
    classNamesFooter.push('georgianStyle georgianStyleFooter')
  } else if (localStorage.getItem('storedLanguage') === "en") {
    classNamesFooter.push('englishStyle englishStyleFooter')
  } else if (localStorage.getItem('storedLanguage') === "ru") {
    classNamesFooter.push('russianStyle russianStyleFooter')
  } else {
    classNamesFooter.push("englishStyle")
  }
    const [t, i18n] = useTranslation("global");
  return (
    <div className="footer">
        <div className='mainFooter'>
            <img src={map} alt="" />
            <div className={`contact ${classNamesFooter.join(" ")}`}>
                <h2>{t("footer.contact")}</h2>

            </div>
        </div>
        <hr />
        <div className="flexBox">
        <a href="#"> <FaFacebook className='socialIcons' />  </a>
        <a href="#"> <FaInstagram className='socialIcons' />  </a>
        <a href="#"> <FaLinkedin className='socialIcons' />  </a>
        </div>
    </div>
  )
}

export default Footer