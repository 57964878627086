import React, {useState} from 'react'
import styled from 'styled-components';
import PhoneNavbar from './PhoneNavbar';



const Burger = () => {
    const [open, setOpen] = useState(false);
  const StyledBurger = styled.div`
      width: 2rem;
      height:2rem;
      /* display:flex;
      justify-content:space-around;
      flex-flow: column nowrap; */
      margin:auto;
      gap: 1rem;
      cursor: pointer;

      ul {
        margin: 11px;

        padding:0;
      }
    

    div {
      width: 2.5rem;
      height:0.3rem;
      background-color: ${({  open }) => open ? '#ccc' : '#333'};
      border-radius:10px;
      margin-bottom: 4px;
      margin-left: -16px;
      transform-origin: 7.6px;
      transition: all 0.3s linear;
      
      &:nth-child(1) {
        transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0deg)'};
        opacity: ${({ open }) => open ? 0.6 : 1};
        background-color: #3c4043 ;
      }
      &:nth-child(2) {
        transform: ${({ open }) => open ? 'traslateX(100%)' : 'translateX(0%) !important'};
        opacity: ${({ open }) => open ? 0 : 1};
        background-color:#3c4043 ;
    }
    
    &:nth-child(3) {
        transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
        opacity: ${({ open }) => open ? 0.6 : 1};
        background-color: #3c4043 ;

      }
@media only screen and (min-width: 900px) {
    display: none;
}

    }
    
`;

    
    return (
        <>
            <StyledBurger className='burger' open={open} onClick={() => setOpen(!open)}>

                <ul>
                    <div />
                    <div />
                    <div />
                </ul>
            </StyledBurger>
            <PhoneNavbar open={open} />
            </>
            )
            
        }
        
export default Burger